import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
 import App from './Home';

//import App from './Ddc';
//import App from './Tsg';

//import App from './Xyh';

//import App from './Dk';
// import App from './Zs';
// import App from './Card';

// import App from './Ft1';
//import App from './Anli';
//import App from './Contact'

//import App from './Mj'

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
