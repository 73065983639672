import React from 'react';
export const Nav32DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children: 'https://smy.jutoo.com.cn/admins/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item~lut6w8807mc',
        className: 'header3-item',
        children: {
          href: 'https://www.jutoo.com.cn/',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <span>首页</span>
                        <br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>产品方案</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            name: 'sub~lu3vjssyjd',
            className: 'item-sub lu3vju6nvb-editor_css',
            children: {
              className: 'item-sub-item lu3vaizj61-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>电子校园卡</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content lu3valegq8h-editor_css',
                  children: (
                    <span>
                      <span>
                        <p>可免费独立部署的校园卡系统</p>
                      </span>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/card.html',
            },
          },
          {
            name: 'sub1',
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3vcq93ml-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <p>智慧招生小程序</p>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: (
                    <span>
                      <p>微信、抖音线上招生</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/zs.html',
            },
          },
          {
            name: 'sub~lu3v6h7ge5',
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3v6wuritr-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>智慧电控服务</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: (
                    <span>
                      <p>支持对接校内充电系统</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/dk.html',
            },
          },
          {
            name: 'sub~lu3v6ifq5u',
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3v8zcnw-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>校友会小程序</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: (
                    <span>
                      <span>
                        <p>校友管理和捐赠服务</p>
                      </span>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/xyh.html',
            },
          },
          {
            name: 'sub~lu3v7fmu55',
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3vaizj61-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>电动车管理和充电</p>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content lu3valegq8h-editor_css',
                  children: (
                    <span>
                      <p>校园电动车和充电桩管理系统</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/ddc.html',
            },
          },
          {
            name: 'sub~lu3wdrd92b',
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3vaizj61-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>图书馆座位预定</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content lu3valegq8h-editor_css',
                  children: (
                    <span>
                      <p>校园电动车和充电桩管理系统</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/tsg.html',
            },
          },
        ],
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>硬件产品</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3vnfh834n-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <p>人脸消费机</p>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: (
                    <span>
                      <p>i基于支付宝人脸算法识别</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/ft1.html',
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item lu3vnfh834n-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <span>
                      <span>
                        <span>
                          <p>人脸门禁机</p>
                        </span>
                      </span>
                    </span>
                  ),
                },
                {
                  name: 'content',
                  className: 'item-content',
                  children: (
                    <span>
                      <p>i基于支付宝人脸算法识别</p>
                    </span>
                  ),
                },
              ],
              href: 'https://www.jutoo.com.cn/cp/mj.html',
            },
          },
        ],
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: 'https://www.jutoo.com.cn/anli.html',
          children: [
            {
              children: (
                <span>
                  <p>合作案例</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: 'https://www.jutoo.com.cn/contact.html',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>
                      <span>联系我们</span>
                      <br />
                    </p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
    subItem: [
      {
        className: 'item-sub',
        children: {
          className: 'item-sub-item',
          children: [
            {
              name: 'image0',
              className: 'item-image',
              children:
                'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
            },
            { name: 'title', className: 'item-title', children: 'Ant Design' },
            {
              name: 'content',
              className: 'item-content',
              children: '企业级 UI 设计体系',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>桔兔校园</p>
              </span>
            </span>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <span>
              <p>高校应用服务商</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>主要提供围绕电子卡身份认证的各类服务定制。</p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>服务指标（2021.1.1-2023.11.31）</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lphzzngva99-editor_css',
                children: (
                  <span>
                    <span>
                      <p>家</p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '30',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>覆盖食堂</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit lphzzx4goa8-editor_css',
                children: (
                  <span>
                    <p>万</p>
                  </span>
                ),
              },
              toText: true,
              children: '13',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>发放电子卡数量</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              toText: true,
              children: '1.4',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>提供交易次数</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <span>
              <span>
                <p>现有软件</p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <span>
              <span>
                <p>你可以直接快速使用以下软件能力</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>电子卡</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>基于支付宝、微信的认证绑定功能</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>收费服务</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    支持导入excel、用户自己填写信息的支付宝、微信的缴费、捐赠功能
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>招生小程序</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>为用户提供一站式招生宣传小程序服务</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>智慧食堂</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    食堂刷脸、刷卡和刷码收款。支持发放餐补、设备可以租用和购买的方式使用
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>常工电子电费充值对接</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>支持对接常工电子中间库，实现支付宝和微信上充值缴费</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>扫码点餐/外卖</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        支持学生、老师在支付宝和微信内，完成扫码点餐和订购外卖。
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>公寓管理</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>z支持管理学校的公寓、人员信息，实时了解信息</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>门禁管理</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    z支持人脸门禁、二维码门禁，管理校门、公寓、办公室等地方等进出
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>解决方案</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'jzjn8afnsxb-editor_css content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item jzjgrrupf2c-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjgrlz134-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzj8xt5kgv7-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>电子卡和食堂支付解决方案</p>
                          <p>
                            <br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        支持高校、高职、中专、企业食堂的电子卡，实现认证、餐补、消费。
                      </p>
                    </span>
                  </span>
                </span>
              ),
              className: 'jzj8z9sya9-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjncn210ql-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjne54fwqm-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>扫码点餐解决方案</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>为食堂商户提供完整的扫码点餐和校园外卖服务。</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndq0dueg-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjne24af8c-editor_css',
              children: (
                <span>
                  <span>
                    <p>招生宣传解决方案</p>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>建立一站式招生宣传小程序服务，支持单招、普招。</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~jzjn87bmyc7',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndsyw8sf-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ',
            },
            {
              name: 'title',
              className: 'content0-block-title jzjndw5oerk-editor_css',
              children: (
                <span>
                  <p>定制服务</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>各类校园相关的小程序定制服务方案。</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>部分客户案例，排序不分先后</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/1.png' },
          content: {
            children: (
              <span>
                <p>辽宁对外经贸学院：扫码点餐/外卖</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/2.png' },
          content: {
            children: (
              <span>
                <p>安康学院：电子校园卡+智慧食堂</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/3.png' },
          content: {
            children: (
              <span>
                <p>安康职业技术学院：智慧食堂</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/4.png' },
          content: {
            children: (
              <span>
                <span>
                  <p>西安卫生学校：电子校园卡+智慧食堂+扫码点餐等</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/5.png' },
          content: {
            children: (
              <span>
                <span>
                  <p>
                    南昌水木源教育集团：智慧食堂+电子校园卡+智慧公寓+智慧电表+智慧水表等
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/6.png' },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>南京信息职业技术学院：智慧电表，网费充值、智慧客服等</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/7.png' },
          content: {
            children: (
              <span>
                <p>湖南商务职业技术学院：招生小程序+打印准考证等</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: 'https://www.jutoo.com.cn/logo/8.png' },
          content: {
            children: (
              <span>
                <p>西安海棠职业学院：扫码点餐+电子校园卡+智慧食堂等</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>&nbsp; &nbsp; <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021010181号-1</a> Copyright © 2024 桔兔科技</p>
      </span>
    ),
  },
};
